<template>
	<div class="index">
		<el-row :gutter="20">
			<el-col :span="6" :xs="24">
				<el-card shadow="hover">
					<p class="box_p">
						注册数：
						<span>{{ dataList.registerNum }}</span>
					</p>
				</el-card>
			</el-col>
			<el-col :span="6" :xs="24">
				<el-card shadow="hover">
					<p class="box_p">
						签到数：
						<span>{{ dataList.signInNum }}</span>
					</p>
				</el-card>
			</el-col>
			<el-col :span="6" :xs="24">
				<el-card shadow="hover">
					<p class="box_p">
						提现金额：
						<span>{{ dataList.withdrawSum }}</span>
					</p>
				</el-card>
			</el-col>
			<el-col :span="6" :xs="24">
				<el-card shadow="hover">
					<p class="box_p">
						付费金额：
						<span>{{ dataList.paySum }}</span>
					</p>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="10">
			<el-col :span="24">
				<el-card shadow="hover">
					<div id="myChart" :style="{ width: '100%', height: '300px' }"></div>
				</el-card>
			</el-col>
			<el-col :span="24">
				<el-card shadow="hover">
					<div id="myChart2" :style="{ width: '100%', height: '300px' }"></div>
				</el-card>
			</el-col>
			<el-col :span="24">
				<el-card shadow="hover">
					<div id="myChart3" :style="{ width: '100%', height: '300px' }"></div>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	data() {
		this.myChart = null;
		this.myChart2 = null;
		this.myChart3 = null;
		return {
			dataList: [],
			productId: ''
		};
	},
	created() {
		if (this.$TOOL.data.get('DATA_SELECTEED') == null || this.$TOOL.data.get('DATA_SELECTEED') == '') {
			// 获取产品类型
			this.$HTTP.post('index/queryProduct', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					if (response.data.code == 0) {
						this.options = response.data.data;
						this.productId = response.data.data[0].key;
						this.listQuery();
					} else {
						this.$message.error(response.data.msg);
					}
				} else {
					console.log(response.message);
				}
			});
		} else {
			this.productId = this.$TOOL.data.get('DATA_SELECTEED');
			this.listQuery();
		}
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				this.listQuery();
			}
		}
	},
	beforeUnmount() {
		this.myChart.dispose();
		this.myChart2.dispose();
		this.myChart3.dispose();
	},
	methods: {
		listQuery() {
			this.$HTTP.post(
				'index/query',
				{
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.dataList = response.data.data;
							this.drawLine();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},

		drawLine() {
			// 基于准备好的dom，初始化echarts实例
			this.myChart = this.$echarts.init(document.getElementById('myChart'));

			// 绘制图表
			this.myChart.setOption({
				title: {
					text: '统计表(注册、签到)'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: ['注册数', '签到数']
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '2%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: this.dataList.dayList
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '注册数',
						type: 'line',
						// stack: 'Total',
						areaStyle: {},
						label: { show: true },
						emphasis: {
							focus: 'series'
						},
						data: this.dataList.registerNumList
					},
					{
						name: '签到数',
						type: 'line',
						// stack: 'Total',
						areaStyle: {},
						label: { show: true },
						emphasis: {
							focus: 'series'
						},
						data: this.dataList.signInNumList
					}
				]
			});

			// 基于准备好的dom，初始化echarts实例
			this.myChart2 = this.$echarts.init(document.getElementById('myChart2'));
			// 绘制图表
			this.myChart2.setOption({
				title: {
					text: '统计表(提现金额)'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: ['提现金额']
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '2%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: this.dataList.withdrawSumDayList
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '提现金额',
						type: 'line',
						// stack: 'Total',
						areaStyle: {},
						label: { show: true },
						emphasis: {
							focus: 'series'
						},
						data: this.dataList.withdrawSumList
					}
				]
			});

			// 基于准备好的dom，初始化echarts实例
			this.myChart3 = this.$echarts.init(document.getElementById('myChart3'));
			// 绘制图表
			this.myChart3.setOption({
				title: {
					text: '统计表(付费金额)'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: ['付费金额']
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '2%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: this.dataList.paySumDayList
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '付费金额',
						type: 'line',
						// stack: 'Total',
						areaStyle: {},
						label: { show: true },
						emphasis: {
							focus: 'series'
						},
						data: this.dataList.paySumList
					}
				]
			});
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.index {
	background: #ffffff;
	padding: 15px;

	.box_p {
		font-size: 30px;

		span {
			font-weight: bold;
			color: #409eff;
		}
	}
}
</style>
